<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    @closed="handleClosed"
    width="min(95vw, 620px)"
    top="2vh"
    lock-scroll
    :destroy-on-close="true"
  >
    <div slot="title">
      <h4 style="margin: 0">mNotify AI</h4>
      <small>Experience digital innovation with the new mNotify AI</small>
    </div>
    <div>
      <el-tabs v-model="activeTab" v-if="dialogVisible">
        <el-tab-pane label="Message Translation" name="translate-text">
          <aiTextTranslation
            @close="dialogVisible = false"
            @move-to-speech="handleChangePage"
          />
        </el-tab-pane>
        <el-tab-pane
          label="Audio Translation"
          name="text-to-speech"
          v-if="currentLoginType !== 'sms'"
        >
          <aiTextToSpeech @close="dialogVisible = false" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>
</template>

<script>
import aiTextTranslation from "./aiTextTranslation.vue";
import aiTextToSpeech from "./aiTextToSpeech.vue";
import loginType from "../../../../helpers/loginType";
export default {
  name: "AIModalContainer",
  components: {
    aiTextTranslation,
    aiTextToSpeech,
  },
  data() {
    return {
      activeTab: "translate-text",
      currentLoginType: loginType(),
    };
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["input", "modal-closed"],
  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
        if (!val) {
          this.$emit("modal-closed");
        }
      },
    },
  },
  methods: {
    handleClosed() {
      this.activeTab = "translate-text";
      this.$store.dispatch("ai/resetTextOutput");
    },
    handleChangePage() {
      this.activeTab = "text-to-speech";
    },
  },
  // created() {
  //   if (this.$route.path.includes("voice")) {
  //     this.activeTab = "text-to-speech";
  //   }
  // },
  // watch: {
  //   activeTab(val) {
  //     if (val === "translate-text" && this.$route.path.includes("voice")) {
  //       this.activeTab = "text-to-speech";
  //     }
  //   },
  // },
};
</script>

<style scoped></style>

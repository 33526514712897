<template>
  <div style="color: initial" v-if="allowedProdFeatureFlag">
    <AIModalContainer
      v-model="dialogVisible"
      @modal-closed="handleModalClosed"
    />
    <el-tooltip content="Start mNotify AI" placement="top-start">
      <el-button
        type="danger"
        circle
        class="ai-start"
        size="small"
        v-bind="$attrs"
        @click="handleClick"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1.4em"
          viewBox="0 0 24 24"
          class="ai-start"
        >
          <path
            fill="currentColor"
            d="m19 1l-1.26 2.75L15 5l2.74 1.26L19 9l1.25-2.74L23 5l-2.75-1.25M9 4L6.5 9.5L1 12l5.5 2.5L9 20l2.5-5.5L17 12l-5.5-2.5M19 15l-1.26 2.74L15 19l2.74 1.25L19 23l1.25-2.75L23 19l-2.75-1.26"
          />
        </svg>
      </el-button>
    </el-tooltip>
  </div>
</template>

<script>
import AIModalContainer from "./aiModalContainer.vue";
import Storage from "@/services/storage";
var storage = new Storage();
export default {
  components: {
    AIModalContainer,
  },
  emits: ["ai-closed"],
  props: {
    campaign: {
      type: Object,
      default: () => ({}),
    },
    beforeOpen: {
      type: Function,
      default: () => true,
      required: false,
    },
    isCreateTemplate: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  provide() {
    return {
      // ! Provided to sub components inside modal
      campaign: () => this.campaign,
      isCreateTemplate: () => this.isCreateTemplate,
    };
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    isFrenchUser() {
      return storage.getSavedState("currentUser").country === "CI";
    },
    allowedProdFeatureFlag() {
      return this.$store.getters["ai/allowedAIFeatureFlagUsers"];
    },
  },
  methods: {
    async handleClick() {
      try {
        const beforeResolve = (await this.beforeOpen()) ?? false;
        if (!beforeResolve) return;
        this.dialogVisible = true;
      } catch (error) {
        return;
      }
    },
    handleModalClosed() {
      this.$emit("ai-closed", this.$store.getters["ai/getTextOutput"]);
      // ! ASK FOR REVIEW
      localStorage.setItem("askedForReview", "true");
    },
  },
};
</script>

<style scoped>
.ai-start {
  position: relative;
}
.ai-start::after {
  /* pulse */
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: pulse 2s infinite;
  border-radius: 50%;
}

/* pulse animation */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(234, 7, 7, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(246, 3, 3, 0);
  }
}
</style>
